<template>
  
  <div>
    
    <ul class="scroller">
      <li><router-link to="fleet-dashboard">Dashboard</router-link></li>
      <li><router-link :to="backLink">{{backTitle}}</router-link></li>
      <li class="is-active"><a aria-current="page">{{tab}}</a></li>
    </ul>
    
  </div>

</template>


<script>
  
export default {

  data: function() {
    return {

    }
  },

  props: {
    tab: String,
    backLink: String,
    backTitle: String
  },

  mounted() {

  },

  methods: {

    


  }

}



</script>


<style lang="scss" scoped>
  
  ul.scroller {
    max-width: none;
    padding-left: 0;
  }


</style>